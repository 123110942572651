import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		if ($(window).width() < 993) {
			//console.log("here" + $('#navrental').attr("data-toggle"));
			$('#navservice').removeAttr("data-toggle");
			$('#navservice').attr("data-toggle", "dropdown");
			$('#navservice').removeAttr("aria-expanded");
			$('#navservice').attr("aria-expanded", "true");
			//$('#navrental').attr("onClick", this.myFunction());



		}





	},

	methods: {

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

		},

		myFunction: function () {

			console.log("myfunction called" + $('#navrental').attr("aria-expanded"));
			if ($('#navrental').attr("aria-expanded") === "true") {
				console.log("inside show condition");
				//$('#navrental').classList.remove("show");
				//$('#navrental').removeClass("show");
				//$('#navrental').attr("aria-expanded", "false");


			} else {
				console.log("inside else of show");
				//$('#navrental').classList.add("show");
				//$('#navrental').addClass("show");
				//$('#navrental').attr("aria-expanded", "true");

			}

		},



		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};