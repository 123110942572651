<template>
    <iframe frameborder="0"
        allowfullscreen
        :src="getUrl()"></iframe>
</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'youtube-video',
    	data() {
    		return {

    		}
    	},
    	props: {
    		videoid: Object,
    	},
    	computed: {},

    	methods: {
    		getUrl: function() {
    			return 'https://www.youtube-nocookie.com/embed/' + this.videoid + '?rel=0';
    		}
    	}
    }
</script>